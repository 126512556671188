import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  SoftwareVersion,
  SoftwareVersions,
  SoftwareVersionsFilters,
} from '@edgeiq/edgeiq-api-js';

import TabsPage from '../../../components/TabsPage';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import { CREATED_NEWEST_SORTING } from '../../../constants/sortings';
import timeHelpers from '../../../helpers/timeHelpers';
import useStyles from '../styles';

const DeviceSoftwareVersions: React.FC = () => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [softwareVersions, setSoftwareVersions] = useState<SoftwareVersion[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography
          noWrap
          variant="button"
          fontWeight={700}
          component="div"
          className={classes.linkButton}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'source',
      flex: 0.3,
      headerName: 'Source',
    },
    {
      field: 'version_raw',
      flex: 0.3,
      headerName: 'Version',
    },
    {
      field: 'revision',
      flex: 0.3,
      headerName: 'Revision',
    },
    {
      field: 'created_at',
      flex: 0.5,
      headerName: 'First seen at',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{timeHelpers.getPlainDate(params.row.created_at)}</span>
      ),
    },
  ];

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);

      const filters: SoftwareVersionsFilters = {
        device_id: {
          operator: 'eq',
          value: id as string,
        },
      };

      SoftwareVersions.list(filters, {
        itemsPerPage: 1,
        order_by: CREATED_NEWEST_SORTING.value,
        page: 1,
      })
        .then((result) => {
          setSoftwareVersions(result.softwareVersions);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDevice]);

  return (
    <Box>
      <TabsPage
        tableTitle="Software Versions"
        columns={columns}
        rows={softwareVersions}
        loading={loading}
      />
    </Box>
  );
};

export default DeviceSoftwareVersions;
